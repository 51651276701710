import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, H4, P } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj11 from "../imgObjs/imgObj11"

import imgGM from "../img/0600/3x4/Geesche-Martin.jpg"
import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Wochenbett"
      keywords={[`Hebamme`, `Frankfurt`, `Beratung`, `Geesche Martin`]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj11} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2, 1 / 3]}>
          <P textAlign={"right"}>
            <em>
              „Erfahrungen vererben sich nicht — jeder muß sie allein machen.“
            </em>
            <br />— Kurt Tucholsky
          </P>
        </Box>
      </Flex>
      <H1>Wochenbett</H1>
      <H2>Angebote nach der Geburt</H2>

      <H4>Psychosoziale Beratung</H4>
      <P>
        Vielleicht haben Sie ein Geburtserlebnis, das nicht ihren Erwartungen
        entspricht und das sie belastet. Oder Sie fühlen sich mit Ihrem
        Neugeborenen und Ihrer Rolle als Mutter überfordert. Wenn Sie
        Unterstützung in der Verarbeitung des Erlebten benötigen, sind Sie bei
        mir herzlich willkommen. Als Beraterin bin ich mit einem reichen
        Erfahrungsschatz an Ihrer Seite und unterstütze Sie, mit Zuversicht der
        neuen Lebensphase entgegenzublicken. Weitere Informationen finden Sie
        auf der Seite{" "}
        <Link to={"/psychosoziale-beratung"}>Psychosoziale Beratung</Link>.
      </P>
      <P>
        In welcher Höhe die Kosten der psychosozialen Beratung über Ihre
        Krankenversicherung abgerechnet werden kann, kläre ich im Einzelfall.
      </P>

      <H4>Wochenbettbetreuung zu Hause</H4>
      <P>
        In der ersten, aufregenden Zeit nach der Geburt betreue ich Sie und Ihr
        Neugeborenes im Rahmen meiner Kapazitäten bei Ihnen zu Hause.
      </P>

      <H4>Wochenbettsprechstunde in der Praxis</H4>
      <P>
        Wenn Sie keine Nachsorgehebamme gefunden haben, aber nicht auf die
        Unterstützung im Wochenbett verzichten möchten, sind Sie herzlich
        willkommen, einen Termin mit mir in der Praxis zu vereinbaren.
        Entsprechend der Betreuung bei einem Hausbesuch schaue ich nach Ihnen
        und Ihrem Neugeborenen und beantworte Ihre Fragen. Bitte melden Sie sich
        vier bis sechs Wochen vor Ihrer Geburt, um einen ersten Termin zu
        vereinbaren.
      </P>
      <P>
        Die Kosten der Wochenbettsprechstunde kann, wie die Wochenbettbetreuung
        zu Hause, über Ihre Krankenversicherung abgerechnet werden.
      </P>
    </Section>
  </Layout>
)

export default IndexPage
